import {useState, useEffect} from "react"
import { ethers } from 'ethers';

const lockId = "4e67f4fb-125a-4ccd-b452-d93732c50c1c"

function App({customerId}) {
  console.log("RENDERING APP")
  console.log("customerId", customerId)
  const [walletAddress, setWalletAddress] = useState(null);
  const [lock, setLock] = useState(null);
  // I should add a fake local cart endpoint for testing, or a live shopify store to test this using the app setup
  const [cart, setCart] = useState(null);
  const [missingPermissionsMessage, setMissingPermissionsMessage] = useState(null);
  const [unlocked, setUnlocked] = useState(false);
  // const [lock, setLock] = useState(null);

  const getLock = async (lockId) => {
    console.log("getLock start")

    const response = await fetch(`https://7x3m3pwwyd.execute-api.us-east-1.amazonaws.com/lock/${lockId}`)

    if(response.status !== 200) {
      setMissingPermissionsMessage("Getting the Lock failed, please try again later.")
      return false
    }

    const responseJson = await response.json();

    console.log("getLock responseJson", responseJson)

    setLock(responseJson)
  }

  // tryUnlock will also get the customer by customerId, check if they are already unlocked. When unlocked we could either auto return true and the actions, or could still double check they still own the NFT. If we don't double check if they own the NFT, separate timed lambda that checks and updates customers to remove the tag if they traded away their NFT. For now we can probably return true if they are already tagged for nicer user experience
  const tryUnlock = async (lockId, walletAddress, customerId) => {

    console.log("tryUnlock start")

    const response = await fetch(`https://7x3m3pwwyd.execute-api.us-east-1.amazonaws.com/lock/${lockId}/check/${walletAddress}${customerId ? `?shopifyCustomerId=${customerId}` : ""}`)

    if(response.status !== 200) {
      setMissingPermissionsMessage("Unlocking failed, please try again later.")
      return false
    }

    const responseJson = await response.json();

    console.log("tryUnlock responseJson", responseJson)

    return responseJson

  }

  const onClickConnectWallet = async () => { 
    const provider = new ethers.providers.Web3Provider(
      window.ethereum,
      "any"
    );
    await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner();

    let userAddress = await signer.getAddress();
    setWalletAddress(userAddress)
  }

  useEffect(() => {
    getLock(lockId)
    fetch('/cart.js')
      .then(response => response.json())
      .then(data => {
        console.log("CART", data)
        setCart(data)
        if (data.attributes.walletAddress) {
          setWalletAddress(data.attributes.walletAddress)
        }
      })
  },[]);

  const onWalletChange = async(lockId, walletAddress) => {
    const unlockResponse = await tryUnlock(lockId, walletAddress, customerId)
    
    if(unlockResponse.unlocked){
      setUnlocked(true)
      let formData = {
        attributes: {
          'walletAddress': walletAddress,
          'signature': unlockResponse.signature
        }
      };

      fetch('/cart/update.js', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      })
      .then(response => {
        return response.json();
      })
      .then(data => {
        console.log("UPDATED CART", data)
         window.location.reload()
       })
      .catch((error) => {
        console.error('Error:', error);
        setMissingPermissionsMessage("Sorry, Something went wrong. Please try again later.")
      });
    } else {
      setMissingPermissionsMessage("Your wallet address is not allowed to access this site.")
    }
  }

  useEffect(() => {
    // TODO: This check should really be for cart wallet addresss or customer wallet address, as one works wihtout auth, but as long as one is present, we should not redirect (or maybe need both to be present or else redirect? Depends if parts depend on exactly cart or exactly customer, Need to make sure liquid code is properly setup too, as it could be checking cart or customer wallet address)
    if(cart && !cart.attributes.walletAddress && lockId && walletAddress) {
      onWalletChange(lockId, walletAddress)
    }
  }, [walletAddress, lockId])
  return (
    !unlocked && !walletAddress && 
    <div style={{width: "100%", padding: "2em"}}>
      <div style={{width: "100%", display: "flex", flexDirection:"column", alignItems: "center", justifyContent: "center"}}>
        {<button onClick={onClickConnectWallet} style={{"backgroundImage":"linear-gradient(92.88deg, #455EB5 9.16%, #5643CC 43.89%, #673FD7 64.72%)","borderRadius":"8px","borderStyle":"none","boxSizing":"border-box","color":"#FFFFFF","cursor":"pointer","flexShrink":"0","fontFamily":"\"Inter UI\",\"SF Pro Display\",-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,Oxygen,Ubuntu,Cantarell,\"Open Sans\",\"Helvetica Neue\",sans-serif","fontSize":"16px","fontWeight":"500","height":"4rem","padding":"0 1.6rem","textAlign":"center","textShadow":"rgba(0, 0, 0, 0.25) 0 3px 8px","transition":"all .5s","userSelect":"none","WebkitUserSelect":"none","touchAction":"manipulation"}}>Connect Wallet</button>}
        {lock && <div style={{marginTop:"2rem"}}>{lock.lockData.message}</div>}
        {lock && lock.lockData.img && <img src={lock.lockData.img} style={{marginTop:"2rem", maxWidth:"60%"}}/>}
        {missingPermissionsMessage && <div>{missingPermissionsMessage}</div>}
        {/* Your Wallet Address is: {walletAddress} */}
      </div>
    </div>
  );
}

export default App;