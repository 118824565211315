import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

console.log("REACT APP INDEX")

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


function init(){
  console.log("INIT REACT APP")
  const rootElement = document.getElementById('nfkey-app-root');
  console.log(rootElement.dataset)
  // Need to make sure when customer logs in we get the updated customer id value!!!
  // put your init code here...
  ReactDOM.render(
    <React.StrictMode>
      <App customerId={rootElement.dataset.nfkeyShopifyCustomerId}/>
    </React.StrictMode>,
    rootElement
  );
}

  if (document.readyState === "complete" || document.readyState === "loaded" || document.readyState === "interactive") {
    // if doc. already loaded, init. script
    init();
  } else{
    console.log("ADDING DOM EVENT LISTENER")
    // otherwise, wait for DOMContentLoaded event and then fire init()
    document.addEventListener("DOMContentLoaded", init, false );
  }